@import '@/styles/variables.scss';









































































































































































































































































































































































































































































































































































































































































































































































































































































.add-employees-btn {
  background: #2830ce;
  border-radius: 3px;
  width: 320px;
}

.hidden-input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
.min-width-text {
  min-width: 160px;
}
.min-width-card {
  min-width: 500px;
}
.th {
  max-width: 280px;
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  display: block;
}
.hidden-input-container:hover {
  background-color: rgba(138, 141, 147, 0.1);
  transition: all 0.3s ease-in-out;
  position: relative;
}
.hidden-input-container {
  background: #ffffff;
  border: 1px solid #2830ce;
  border-radius: 3px;
  height: 139px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.my-bav {
  &__table {
    .v-select__selection {
      color: #2830ce !important;
    }

    .v-data-table__checkbox,
    .v-input--checkbox {
      .v-icon__svg,
      .v-icon {
        color: #2830ce !important;
      }
    }

    .v-data-footer {
      color: #2830ce !important;
      border-color: rgba(40, 48, 206, 0.25) !important;
      button.v-btn--disabled {
        opacity: 0.4;
      }
      svg {
        fill: #2830ce;
      }
    }
    th {
      background: rgba(40, 48, 206, 0.25) !important;
      border-top: 1px solid rgba(40, 48, 206, 0.15) !important;
      border-color: #2830ce !important;
      border-bottom: none !important;
      span {
        color: #2830ce !important;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
      }
    }
    tr,
    td {
      border-color: rgba(40, 48, 206, 0.25) !important;
    }
    &-card {
      border-radius: 3px !important;
      /*margin-top: 31px;*/
      border-color: rgba(40, 48, 206, 0.25) !important;
    }
    &-heading {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #505a68;
    }
    &-filter {
      /*max-width: 320px;*/
      min-width: 320px;
    }
    &-info {
      padding: 10px 8px;
      border: 1px solid #2830ce;
      border-radius: 3px;
      margin: 8px 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      background-color: white;
      /*transform: translateX(50%) translateY(10px);*/
      color: #505a68;

      ul {
        padding: 0;
        padding-left: 12px;
      }
      &-title {
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        color: #2830ce;
        margin-bottom: 3px;
      }
    }
  }
}

@media only screen and (max-width: 959px) {
  .add-employees-btn {
    margin: 8px 0 !important;
    width: 100% !important;
  }
  .my-bav {
    &__table {
      table {
        border: 1px solid rgba(40, 48, 206, 0.15) !important;
      }
    }
    .v-data-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
    }
    .v-data-footer__icons-after,
    .v-data-footer__icons-before {
      max-width: 16px;
      max-height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .v-data-footer__select {
      min-width: unset !important;
    }
    .v-data-footer__pagination {
      margin: 0 10px;
    }
  }
}
